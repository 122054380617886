import { Routes } from "@angular/router";

export const content: Routes = [
  // {
  //   path: "dashboard",
  //   loadChildren: () => import("../../components/single-page/single-page.module").then((m) => m.SinglePageModule),
  // },
  {
    path: "",
    loadChildren: () => import("../../order/order.module").then((m) => m.OrderModule),
  },
  {
    path: "order",
    loadChildren: () => import("../../order/order.module").then((m) => m.OrderModule),
  },
];
