import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import UserLogin from "src/app/models/request/user-login.model.request";
import { AuthService } from "../auth.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})

export class LoginComponent implements OnInit {
  public newUser = false;
  public loginForm: FormGroup;
  public show: boolean = false
  public errorMessage: string;

  constructor(private fb: FormBuilder, public router: Router, private authService: AuthService) {
    this.loginForm = this.fb.group({
      cod_acesso: ["", [Validators.required]],
      senha: ["", Validators.required],
    });
  }

  ngOnInit() { }

  async login() {
    const user = this.loginForm.value as UserLogin

    try {
      const response = await this.authService.authenticate(user);

      if (response.token == null)
        return this.errorMessage = "Código de Acesso e/ou senha incorreto."

        localStorage.setItem("user_datum", JSON.stringify(response.user));
        localStorage.setItem("current_session", response.token);
        this.router.navigate(["/"]);
    } catch {
      this.errorMessage = "Código de Acesso e/ou senha incorreto."
    }
  }

  showPassword() {
    this.show = !this.show
  }
}
