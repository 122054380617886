import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";
import User from "src/app/models/user.model";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  public user: User;
  public profileImg: "assets/images/dashboard/profile.jpg";

  constructor(public router: Router, private authService: AuthService) {
    this.user = this.authService.pegaUsuario();
  }

  ngOnInit() {}

  logoutFunc() {
    this.authService.logoff();
  }
}
