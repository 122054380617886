import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment'
import { HttpClient } from '@angular/common/http';
import UserLogin from '../models/request/user-login.model.request';
import UserLogged from '../models/response/user-login.model.response';
import User from '../models/user.model';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    endpoint = environment.endpoint;

    constructor(
        private http: HttpClient,
        private router: Router) { }

    authenticated() {
        let accessToken = localStorage.getItem('current_session');
        return accessToken != null && accessToken != "" ? true : false
    }

    async authenticate(user: UserLogin): Promise<UserLogged> {
        return await this.http.post<UserLogged>(`${this.endpoint}/auth`, user).toPromise();
    }

    async logoff() {
        localStorage.removeItem('current_session');
        localStorage.removeItem('user_datum');
        this.router.navigate(['login']);
    }

    pegaUsuario(): User
    {
        return JSON.parse(localStorage.getItem('user_datum')) as User;
    }

    isSeller(): boolean {
        const user: User = this.pegaUsuario();

        return (user.tipo_usuario != "administrador" && user.tipo_usuario != "gerente") && user.cod_vendedor != null;
    }

    isRecapadora(): boolean {
        const user: User = this.pegaUsuario();

        return user.tipo_usuario == "administrador" || user.tipo_usuario == "gerente" || user.tipo_usuario == "recapadora";
    }

    pegaCodigoVendedor(): number {
        const user: User = this.pegaUsuario();

        return user.cod_vendedor;
    }
}