import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot) {
    const authenticated = this.authService.authenticated();

    if (route.routeConfig && route.routeConfig.path !== 'login' && !authenticated) {
      this.router.navigate(['login']);
      return false;
    }
    
    return true;
  }

}
